.bgTrivia {
    height: 100%;
    background-image: url('../../../assets/images/svg/LandingPage/svg/horse.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 2rem;
    display: flex;
    align-items: center;
}

.masknew {
    background-image: url('../../../assets/images/svg/LandingPage/svg/mask-figure.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 2rem;
    border: 1px solid #707070;
    margin: 10px;
    border-radius: 15px;
}

.masknews {
    display: flex;
    padding: 10px;
    background: #212121;
    margin: 10px;
    border-radius: 16px;
    background: linear-gradient(to bottom, #212121, #000000);

}

.numbers {
    background-color: deepskyblue;
    display: flex;
    margin: 5px;
    border-radius: 8px;
    width: 60px;
    height: 45px;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.progress {
    width: 90px;
}

.small,
small {
    font-size: 10px;
}

@media screen and (max-width: 1000px) {
    .masknews {
        padding: .2rem 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .numbers {
        width: 45px;
        height: 40px;
    }

    .progress {
        width: 70px;
        margin-top: 10px;
    }

}

@media screen and (max-width: 640px) {
    .masknews {
        padding: 5px;
    }

    .masknew {
        width: 100%;
        padding: 0.8rem;
    }

    .numbers {
        width: 40px;
    }
}

@media screen and (max-width: 575px) {

    .rightSection {
        display: flex !important;
        padding: unset;
    }

    .masknews {
        padding: 5px;
        width: 100%;
        min-width: 150PX;
        overflow: auto;
    }

    .numbers {
        padding: 5px;
        border-radius: 8px;
        width: 25px;
        height: 25px;
        font-size: 10px;
    }



    .small,
    small {
        font-size: 8px;
    }

    .progress {
        width: 40px;
        margin-top: 0px;
    }

}

@media screen and (max-width: 405px) {

    .small,
    small {
        font-size: 7px;
    }

    .masknew {
        padding: 0.5rem;
        min-width: 152px;
    }
    .masknew h3{
        font-size: 17px !important;
    }
}
