.productCarousel {
    position: relative;
    overflow: hidden;
    padding: 26px 0px;
}

.customList {
    padding: 0 10px;
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: .5rem;
}

.preBtn,
.nextBtn {
    border: none;
    width: 60px;
    /* height: 100%; */
    margin: 15% 0;
    height: 20%;
    position: absolute;
    top: 0;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
}

.preBtn {
    justify-content: flex-start;
}

.nextBtn {
    right: 0;
    justify-content: flex-end;
}

.nextBtn p,
.preBtn p {
    /* font-size: 50px; */
    background-color: rgba(255, 255, 255, 0.436);
    box-shadow: 0px 0px 10px 1px black;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    /* width: 50px;
    height: 50px; */
    cursor: pointer;
    background-color: #fff;
    color: #000;
    border-radius: 50%;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.ellipsisOne {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.title {
    font-size: 18px;
    margin-bottom: 10px;
    font-family: var(--fontFamilyRegular);
    color: var(--fontColorWhite);
    margin: 0 1rem .1rem;
    text-transform: capitalize
}

.cardText {
    font-size: clamp(8px, 1.1vw, 30px);
    font-family: Arial;
    font-weight: 400;
    line-height: 1em;
    text-align: left;
    color: #FFFFFF;
    /* max-height: 2.4em;
    height: 2.4em; */

}

.usernameText {
    font-size: clamp(8px, 1.2vw, 17px);
}

.circle {
    height: 0.32rem;
    width: .32rem;
    background-color: rgb(187, 187, 187);
    border-radius: 50%;
    display: inline-block;
}

.watching {
    font-size: clamp(8px, 1vw, 22px);
}

.description {
    font-size: clamp(8px, .9vw, 22px);
}