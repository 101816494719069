.searchInputField {
    border-radius: 50px;
    border: 1px solid #262626;
    background-color: #262626;
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    color: #fff;
    outline: none;
    background-image: url('../../assets/images/svg/Navbar/Search.svg');
    background-position: 15px center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
}