.vidTitle {
    font-family: var(--fontFamilyPoppins);
    font-size: clamp(20px, 4vw, 25px);
    font-weight: 600;
    line-height: 75px;
    text-align: center;
}

.tabs {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: 1px solid #E5E5E5;
    margin: auto;
    gap: 10%;
}

.tab {
    font-family: var(--fontFamilyPoppins);
    font-size: clamp(14px, 4vw, 16px);
    font-weight: 700;
    line-height: 37.5px;
    cursor: pointer;
}

.activeTab {
    color: #EE3D42;
}

.cardTitle {
    font-family: var(--fontFamilyPoppins);
    font-size: clamp(14px, 4vw, 18px);
    font-weight: 500;
    line-height: 52.5px;
    text-align: left;

}

.card {
    border: 1px solid #707070;
    background: #171717;
    height: 40%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 1.5%;
    width: 30%;
    z-index: 9;
    position: relative;
}

.bgCardImg {
    width: 50%;
    height: 68%;
    position: absolute;
    z-index: 3;
    right: -12%;
}

.cardsTitle {
    font-size: clamp(14px, 4vw, 18px);
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-family: var(--fontFamilyPoppins);
}

.cardTitleData {
    font-size: clamp(10px, 4vw, 14px);
    padding: 0;
    margin: 0;
    font-family: var(--fontFamilyPoppins);
    font-weight: 600;
}

/* Analytics */
.cardAnlt {
    width: 50% !important;
    padding: 2%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    height: fit-content;
}

.cardTitleDataAnalytic {
    font-size: clamp(10px, 4vw, 20px);
    font-family: var(--fontFamilyPoppins);
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.cardAnalyticNumber {
    font-family: var(--fontFamilyPoppins);
    font-size: clamp(20px, 4vw, 50px);
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.cardAnalyticBg {
    position: absolute;
    width: 25%;
    right: 5%;
    top: 25%;
}

.cardAnalyticBgView {
    position: absolute;
    width: 28%;
    right: 10%;
    top: 25%;
}

.NoOfViews {
    background: #171717;
    border-radius: 10px;
    padding: 5%;
    width: 100%;
    height: fit-content;
}

.noViews2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.viewText {
    font-family: var(--fontFamilyPoppins);
    font-weight: 400;
    font-size: clamp(14px, 4vw, 18px);
    text-transform: uppercase;
}

.box1 {
    display: flex;
    flex-direction: column;
    gap: 2%;
    width: 45%;
}

.box2 {
    display: flex;
    flex-direction: column;
    gap: 2%;
    width: 45%;
}

.centerTxt {
    text-align: center;
}

.width80 {
    width: 80% !important
}

.chart {
    width: 100%;
    background: #171717;
    border-radius: 10px;
    padding: 5%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}