.cardButton {
    text-transform: uppercase;
    display: block;
    align-items: center;
    /* background-color: var(--backgroundColorOrange) !important; */
    color: var(--fontColorWhite) !important;
    font-family: var(--fontFamily);
    margin-bottom: 10px;
    font-size: 14.8px;
    text-decoration: none !important;
    padding: 1.6vh;
    text-align: center;
    width: 100%;
    height: 6.3vh;
    border: none;
    border-radius: 11px;
    /* margin-left: 6px; */
    margin-top: 1rem;
}

.active {
    background: rgb(247, 64, 55);
    color: white;
    font-weight: bold;
    border: transparent;
}

.inActive {
    background: none;
    border: 3px solid rgb(247, 64, 55);
    color: rgb(247, 64, 55);
    font-weight: bold;
}

.modalbtn {
    background: rgb(247, 64, 55);
    min-width: 9rem;
    font-weight: unset;
    border-radius: 7px;
    border: none;
    padding: 9px;
    color: #fff;
    margin-left: 13px;
}

.modalbtns {
    background: #3485FF;
}

.signinas {
    width: 100%;
    height: 70px;
    border-radius: 20px;
    max-width: 260px;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.skip {
    background: none;
    color: var(--fontColorWhite) !important;
    font-weight: bold;
    font-family: var(--fontFamily);
    text-transform: uppercase;
    display: block;
    align-items: center;
    font-size: 14.8px;
    text-align: center;
    width: 100%;
    height: 6.3vh;
    border: none;
    border-radius: 11px;
    /* margin-top: 1rem; */
}

.previous {
    color: var(--fontColorWhite);
    background: none;
    border: none;
}

.signin {
    border-radius: 10px;
    height: 100%;
    padding: 6px 20px;
    margin-right: 10px;
    font-weight: unset !important;
    border: none;
    text-transform: none !important;

}

.signup {
    border-radius: 10px;
    height: 100%;
    padding: 6px 20px;
    font-weight: unset !important;
    background: white;
    color: #f74037;
    border: none;
    text-transform: none !important;

}

.circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 0;
    font-weight: unset !important;
    background-color: rgb(38, 38, 38);
    border: none;
    text-transform: none !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    cursor: pointer;
    margin-left: 0.5rem;
}

.message {
    background-image: url('../../assets/images/svg/Navbar/messageIcon.svg');
}

.uploadVideo {
    background-image: url('../../assets/images/svg/Navbar/uploadVideoIcon.svg');
}

.notification {
    background-image: url('../../assets/images/svg/Navbar/notificationIcon.svg');
}

.profile {
    /* background-image: url('../../assets/images/svg/Navbar/profileIcon.svg'); */
    background-size: 45px;
}

.SidebarDash {
    background-image: url('../../assets/images/svg/Sidebar/SideDash.svg');
    background-size: 45px;
}

.welcomeUploadRadar {
    background-image: url('../../assets/images/svg/RadarPage/cloudIcon.svg');
}

.trivia {
    background: #FF0000;
    border-radius: 2rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    margin: .5rem;
    border: none;
    color: white;
}

@media screen and (max-width: 392px) {
    .signin {
        padding: 6px 10px;
    }

    .signup {
        padding: 6px 10px;
    }
}

.wideLess {
    width: 22vw;
}

.w50 {
    width: 40%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.modaltimelinebrowse {
    font-size: 10px !important;
    font-weight: unset;
}

.modaltimeline {
    background-color: #f74037;
    border: none;
    min-width: 140px;
    color: #fff;
    border-radius: 7px;
    font-size: 14px;
    padding: 10px;
}


.participate {
    background-color: #f74037;
    border: none;
    color: #fff;
    min-width: 200px;
    border-radius: 7px;
    font-size: 12px;
    padding: 5px;
    margin-top: 15px;
    font-weight: unset !important;
}

.participates {
    background-color: #f74037;
    border: none;
    color: #fff;
    border-radius: 7px;
    font-size: 12px;
    padding: 8px;
    margin-top: 15px;
    font-weight: unset !important;
}

.modaltimelineG {
    background-color: transparent;
    border: 1px solid green;
    width: 140px;
    color: green;
    border-radius: 7px;
    font-size: 14px;
    padding: 5px
}

.roundButton {
    display: flex;
    color: white;
    border-radius: 50px;
    padding: 5px 12px;
    font-size: 15px;
    align-items: center;
    width: max-content;
    grid-gap: 10px;
    cursor: pointer;
}

.titleCase {
    text-transform: capitalize !important;
}

/* Video Page's */
.videoPage {
    /* width: 100%; */
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-family: var(--fontFamilyPoppins);
    font-size: clamp(12px, 0.5vw, 15px);
    font-weight: 400;
    line-height: 22.5px;
    text-align: left;
    padding: 0 2%;
}

.modalpass {
    background-color: #f74037;
    border: none;
    width: 140px;
    color: #fff;
    border-radius: 7px;
    font-size: 14px;
    padding: 5px;
    width: 15rem;
}

.signinasSetting {
    width: 100%;
    height: 70px;
    max-width: 260px;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    padding: 0.8rem;
    margin-top: 10px;
    text-transform: none !important;
}

.signinasSetting_w {
    border-radius: 10px;
    height: 100%;
    padding: 4px 38px;
    font-weight: unset !important;
    background: #000;
    color: #fff;
    border: none;
    text-transform: none !important;
    border: 1px solid #fff;
    font-size: 14px;
}

.cancelTeal {
    background-color: #43B5D8;
}

.goBackButton {
    font-size: clamp(12px, 4vw, 15px);
    border: none;
    background: none;
}

.searchFollow {
    font-size: clamp(8px, 4vw, 12px);
    border-radius: 4px;
    max-width: fit-content;
    padding: 1% 50%;
    text-transform: capitalize !important;
}

.searchUnFollow {
    border-radius: 4px;
    max-width: fit-content;
    padding: 1% 50%;
    font-size: clamp(8px, 4vw, 12px);
    border: 1px solid #fff !important;
    background-color: #121214 !important;
    text-transform: capitalize !important;
}

.viewAll {
    border: none;
    max-width: 50%;
    border-radius: 7px;
    font-size: clamp(8px, 4vw, 12px);
    padding: 2% 10%;
    font-family: var(--fontFamilyPoppins);
}

.timelineLevel1Follow {
    border: 2px solid #F74037;
    background-color: #000;
    color: #F74037;
    font-family: var(--fontFamilyPoppins);
    border-radius: 8px;
    font-weight: lighter;
    margin: 0 2%;
    padding: 2% 15%;
}

.timelineLevel1Following{
    border: 3px solid #F74037;
    background-color: #F74037;
    color: #000;
    font-family: var(--fontFamilyPoppins);
    border-radius: 8px;
    font-weight: lighter;
}