.heading {
    font-family: var(--fontFamilyPoppins);
    color: inherit;
    text-align: center;
    font-size: clamp(16px, 1.2vw, 20px);
    font-weight: 700;
}

.body {
    font-family: var(--fontFamilyPoppins);
    font-size: clamp(10px, 2vw, 13px);
    color: #FFFFFF;
    font-weight: 400;
}

.checkboxform {
    margin: 2% 0;
    border-radius: 5px;
    border: 2px solid #F74037;
    padding: 1%;
    display: flex;
    gap: 1%;
}

.checkboxform input[type="checkbox"]:checked {
    background-color: #F74037;
}

.checkbox {
    width: 20px;
}

.checkboxform input[type="checkbox"] {
    display: none; /* Hide the default checkbox */
  }
  
  .checkboxform input[type="checkbox"] + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    display: inline-block;
  }
  
  .checkboxform input[type="checkbox"] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 2px;
    background: white;
  }
  
  .checkboxform input[type="checkbox"]:checked + label:before {
    background: #F74037; /* Change background color to red when checked */
  }
  
  .checkboxform input[type="checkbox"]:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 0px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  