.customModal {
    position: relative;
    /* min-height: 40%;
    max-width: 40%; */
    border-radius: 15px;
    align-self: center;
    padding: 2rem;
    background: black;
}

.hoverModal {
    position: absolute !important;
    z-index: 9 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 50vh; */
}

@media screen and (max-width: 768px) {

    /* For tablet screens and smaller */
    .customModal {
        max-width: 100% !important;
    }
}

@media screen and (max-width: 480px) {

    /* For phone screens */
    .customModal {
        max-width: 100% !important;
    }
}

.centered {
    background-color: #000000;
}