/* steps */
body {
  overflow-x: hidden;
}

/* //////////////// */

.font-20 {
  font-size: 20px;
}

.border-radiu-10 {
  border-radius: 10px;
}

.font-25 {
  font-size: 25px;
}

.w-100 {
  width: 100%;
}

.h-500 {
  height: 500px
}

.bg-img {
  background-color: #f1f1f1;
}

.boredr-0 {
  border: 0;
}

.border-bottom {
  border-bottom: 1px solid black;
}

.h-300 {
  height: 300px;
}

.border-1 {
  border: 1px solid #CECECE;
}

.p-0 {
  padding: 0;
}

.text-chech {
  color: #B1B1B1;
}

.bg-theme {
  background-color: #F74037;
}

.w-80 {
  width: 80%;
}

.border-5 {
  border-radius: 5px;
}

.bg-light-button {
  background-color: #FED5D5;
}

.button {
  padding: 13px 80px;
}

.border-30 {
  border-radius: 30px;
}

.font-11 {
  font-size: 11px;
}

.font-14 {
  font-size: 14px;
}



.steps-main {
  max-width: 900px;
  margin: 0 auto;
}

.steps-main .w-20-show:first-child::before,
.steps-main .w-20-show:last-child::after {
  display: none;
}

.circle-steps {
  width: 50px;
  height: 50px;
  background: #ccc;
  border-radius: 50%;
  display: flex;
  z-index: 9;
}

.w-20-show.active .circle-steps,
.w-20-show.active::after,
.w-20-show.active::before {
  background: #F74037;
}

.w-20-show {
  width: 20%;
  display: flex;
  position: relative;
}

.w-20-show::before {
  content: '';
  position: absolute;
  height: 5px;
  background: #ccc;
  left: 0px;
  right: 50%;
  top: 22px;
}

.w-20-show::after {
  content: '';
  position: absolute;
  height: 5px;
  background: #ccc;
  left: 50%;
  right: 0px;
  top: 22px;
}

/* .img-box img {
  position: absolute;
  top: 50%;
  left: 4%;
} */

span .close {
  position: absolute;
  top: 9px;
  right: 5px
}

.redio {
  position: absolute;
  top: 3px;
  left: 3px;
}

@media only screen and (max-width: 568px) {
  .border-1 {
    width: 100%;
  }
}

/* span {
  font-size: 15px;
} */

.w-70 {
  width: 70px;
}

.font-16 {
  font-size: 16px;
}

.w-70pr {
  width: 70%;
}

/* .nav{
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.nav-link {
  color: white;
} */
.w-100 {
  width: 100%;
}

.font-600 {
  font-weight: 600;
}

.tabs span a {
  text-decoration: none;
}

.font-24 {
  font-size: 1.5rem;
}

.border-10 {
  border-radius: 10px;
}

.w-38 {
  width: 38px;
}

.border-5 {
  border-radius: 5px;
}

.text-theme {
  color: #F74037;
}


/* /////////////// step wizard-strat*/
.steps-main {
  max-width: 900px;
  margin: 0 auto;
}

.steps-main .w-20-show:first-child::before,
.steps-main .w-20-show:last-child::after {
  display: none;
}

.circle-steps {
  width: 50px;
  height: 50px;
  background: #262626;
  border-radius: 50%;
  display: flex;
  z-index: 9;
}

.w-20-show.active .circle-steps,
.w-20-show.active::after,
.w-20-show.active::before {
  background: #F74037;
}

.w-20-show {
  width: 20%;
  display: flex;
  position: relative;
}

.w-20-show::before {
  content: '';
  position: absolute;
  height: 5px;
  background: #262626;
  left: 0px;
  right: 50%;
  top: 22px;
}

.w-20-show::after {
  content: '';
  position: absolute;
  height: 5px;
  background: #262626;
  left: 50%;
  right: 0px;
  top: 22px;
}

/* /////////// step wizard end */

/* side preview start */
.img-box {
  background-color: #F9F9FF;
  width: 100%;
  height: auto;
  border-radius: 14px;
  display: flex;
}

.bg-text {
  background-color: #F9F9FF;
}

/* .alt-img {
    position: absolute;
    top: 50%;
    left: 4%;
    transform: translateY(-50%);
    width: 90%;
} */
/* side preview start */

/* drap and drop image start */
.drag-area {
  height: 400px;
  border: 3px dashed #e0eafc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
}

/* h3 {
    margin-bottom: 20px;
    font-weight: 500;
  } */

.drag-area .icon {
  font-size: 50px;
  color: #1683ff;
}

.drag-area .header {
  font-size: 20px;
  font-weight: 500;
  color: #34495e;
}

.drag-area .support {
  font-size: 12px;
  color: gray;
  margin: 10px 0 15px 0;
}

.drag-area .button-file {
  font-size: 20px;
  font-weight: 500;
  color: #1683ff;
  cursor: pointer;
}

.drag-area.active {
  border: 2px solid #1683ff;
}

.drag-area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* drap and drop image end */

.customLabel {
  color: #393636;
  font-size: 1.8vh;
  margin-bottom: 0px;
  margin-top: 1.25vw;
}

/* dropdown  */
.dropDownIndicator {
  padding: 0px;
}

.dropDownIcon {
  height: 2.2vw;
  width: 2.2vw;
}

.drag-area {
  height: 250px;
}

.position-m {
  position: absolute;
  top: 8px;
  right: 4px;
}

.position-r {
  position: absolute;
  top: 0;
}

.form-check-input:checked {
  background-color: #F74037;
  border: 1px solid #F74037;
  box-shadow: none;
}

/* //////// cutom readio button css */

.form-check-input {
  /* position: absolute;
  top: 1px;
  left: 8px; */
  width: 1.2em !important;
  height: 1.2em !important;
}

.form-check {
  padding-left: -0.5em;
}

.form-check .form-check-input {
  float: left;
  margin-left: -0.5em;
}

/* //////// cutom readio button css */


/* save popup */
.saveAlert {
  display: 'flex';
  justify-content: center;
  z-index: 999 !important;
  width: inherit;

  top: 5%;
  right: 5%;
}

.saveAlert .alertContainer {
  position: relative;
  height: 80px;
  width: 300px;
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.saveAlert .closeBtn {
  color: #f74037 !important;
  cursor: pointer;
  margin-left: 10px;
}

.saveAlert h5 {
  color: #228b22 !important;
}

.customInput {
  border: none !important;
  border-bottom: 1px solid #e1e1e1 !important;
  color: #000000 !important;
  font-family: var(--fontFamilyMedium);
  font-size: 0.9rem;
}

.pb-5 {
  padding-bottom: 20px;
}

.customInput:focus {
  box-shadow: none;
}

/* /////////////////// */

@media only screen and (max-width: 1445px) {
  .text-b {
    font-size: 11px;
  }
}

/* @media only screen and (max-width: 992px)  {
  .responsive-box {
    padding: 0 30px;
  }
} */
@media only screen and (max-width: 1265px) {
  .check-box {
    margin: 20px 0;
  }
}

@media only screen and (max-width: 992px) {
  .form {
    padding: 30px 0;
  }

  .img-box {
    background-color: #F9F9FF;
    width: 100%;
    height: 250px;
    border-radius: 14px;
    display: flex;
  }
}

@media only screen and (max-width: 518px) {
  .ProfileData_profileSection {
    margin-top: 200px;
  }

  .img-box {
    background-color: #F9F9FF;
    width: 100%;
    height: 250px;
    border-radius: 14px;
    display: flex;
  }
}

/* .modal-dialog {
  max-width: 30%;
} */


/* .owl-next span {
	font-size: 70px;
	position: absolute;
	right: 0;
	z-index: 999;
	padding: 0px 10px;
	top: 390px;
	color: white !important;
	background-color: hsl(0, 0%, 33%);
	}
	
	.owl-prev span {
	font-size: 70px;
	position: absolute;
	z-index: 999;
	padding: 0px 10px;
	top: 390px;
  left: 0;
	color: white !important;
	background-color: hsl(0, 0%, 36%);
	}
  .owl-next span:hover {
    background-color: white;
    color: #F74037 !important;
  }
  .owl-prev span:hover {
    background-color: white;
    color: #F74037 !important;
  }
  @media only screen and (max-width: 576px){
  .owl-next {
  position: absolute;
  top: -110px;
  right: 0px;
  }
  .owl-prev {
  position: absolute;
  top: -110px;
  }
  }

  .owl-carousel .owl-item img {
    height: 800px;
  }

  .owl-dots {
    position: absolute;
    left: 49%;
    bottom: 60px;
  } */

.carousel-control-next-icon {
  position: absolute;
  right: 0;
  background-color: #000000;
  padding: 30px 10px;
  border-radius: 5px;
}

.carousel-control-prev-icon {
  position: absolute;
  left: 0;
  background-color: #000000;
  padding: 30px 10px;
  border-radius: 5px;
}


@media only screen and (max-width: 1280px) {
  .menu-block {
    display: block;
    display: flex;
  }

}

.form-check .form-check-input {
  float: left;
  /* margin-left: 0em !important; */
}

.py-1 {
  /* padding-top: 0.25rem!important; */
  padding-bottom: 0.55rem !important;
}