.inputBox {
    border: none;
    border-bottom: 1px solid #707070;
    background-color: black;
    color: white;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    height: 2rem;
    margin-top: 5px;
    appearance: none;
}

.inputBox option {
    margin: 40px;
    background: #313131;
    color: #fff;
    border: none;
    outline: none;
}

.inputBox option:hover {
    background-color: red !important;
}

.form-select option:hover {
    background-color: lightgray;
    /* Change this to the desired hover color */
}

.heading {
    font-family: 'Poppins-bold', sans-serif;
    font-size: 35px;
    font-weight: 510;
    margin-left: 1px !important;
    color: #F74037;
}

.socialIcon {
    width: 3.0rem;
    height: 3.0rem;
}

.labelPersonalize {
    font-size: 12px;
    padding-top: 1rem;
}

.reactSelectContainer {
    background: var(--fontColorBlack);
}

.customChips {
    justify-content: space-between;
    background-color: #fed5d5;
    border-radius: 10px;
    color: var(--backgroundColorOrange);
    font-family: var(--fontFamily);
    font-size: 2vh;
    /* height: 3.8vh; */
    padding: 0 5px;
    flex-direction: row;
    display: flex;
    margin-right: 5px;
    margin-top: 0.5rem;
    text-align: center;
}

.passwordIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.inputErrorMessage {
    color: #F74037;
    font-size: 10px;
}

/* checkbox */
.container {
    display: block;
    position: relative;
    padding-left: 4%;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: clamp(10px, 2vw, 13px);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    /* top: 9%; */
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #F74037;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 3.5px;
    left: 3.5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.fillBorder {
    display: flex;
    gap: 2%;
    background: #FED5D5;
    border-radius: 20px;
    color: #F74037;
    padding: 1% 5%;
    margin: 1% 0;
    align-items: center;
    justify-content: center;
}

.rowOptions {
    display: flex;
}

/* input box number */
.inputBoxNum {
    width: 10%;
    height: 100%;
    border: 2px solid #3D3D3D;
    background-color: black;
    color: white;
    border-radius: 5px;
    font-size: clamp(10px, 2vw, 16px);
    text-align: center;
    margin: 0 5px;
    outline: none;
    box-sizing: border-box;
}

.inputBoxNumYear {
    width: 10%;
    height: 100%;
    border: 2px solid #3D3D3D;
    background-color: black;
    color: white;
    border-radius: 5px;
    font-size: clamp(10px, 2vw, 16px);
    text-align: center;
    margin: 0 5px;
    outline: none;
    box-sizing: border-box;
}

.fullBorder {
    border: 2px solid #3D3D3D;
    border-radius: 5px;
    height: clamp(2rem, 6vw, 3rem);
}

.labelLive {
    font-family: var(--fontFamilyPoppins);
    font-size: clamp(8px, 5vw, 12px);
    color: #7D7D7D;
}

.timelineSearchBox {
    width: 100%;
    color: inherit;
    font-family: var(--fontFamilyPoppins);
    background-color: #18181C;
    border: 1px solid #515151;
    border-radius: 4px;
    padding: 2% 2% 2% 10%;
    background-repeat: no-repeat;
    background-position: 10px 50%;
    background-size: 5%;
    max-height: 4%;
}

.timeSearchLevel1 {
    width: 100%;
    margin: auto;
    color: inherit;
    font-family: var(--fontFamilyPoppins);
    background-color: #18181C;
    border: 1px solid #515151;
    border-radius: 4px;
    padding: 2% 2% 2% 5%;
    background-repeat: no-repeat;
    background-position: 10px 50%;
    background-size: 3%;
    max-height: 0.5rem;
}